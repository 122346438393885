<template>
  <div align="center">
    <v-container class="top-frame" v-if="faretype == 3 || faretype == 4">
      <v-card elevation="1" class="card-content">
        <v-row align="center" class="ma-0 pa-4 pb-0 pt-0">
          <v-col
            class="ma-0 px-0 py-5 d-flex text-center justify-center"
            cols="12"
          >
            <h2 class="pl-2 ticket text-color-green">
              {{ $t("EvoucherTicket") }}
            </h2>
          </v-col>
        </v-row>
        <v-row class="text-left" style="border-top: 1px solid #cecece">
          <v-col class="px-7 pb-0">
            <b style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">{{
              $t("TripInformation")
            }}</b
            ><br />
            {{ $t("Price") }} :
            {{ Intl.NumberFormat().format(item.price) }}
            {{ item.currency_code }}/{{ $t("Turn") }}<br />
            {{ $t("ValidUntil") }} : {{ validate_end_date || '-' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="my-0 px-7 text-left">
            <div class="text-color-green">
              {{ $t("TicketAvailable") }}
              {{ parseInt(item.max_trip) - item.used_ticket }}
              {{ $t("Times") }}
            </div>
          </v-col>
        </v-row>
        <!-- <v-row align="center">
              <v-col cols="12" class="text-center py-0 primary--text">
                  <h1>{{item.fare_name}} </h1>
                  <span class="grey--text"> &#9679; {{item.max_trip_perday}} {{$t("Trips_day")}} &#9679;</span>
              </v-col>
          </v-row>
          <v-row class="my-3">
              <v-col class="my-0 py-0 text-center">
                  <div class="caption text-color-date font-weight-light" style="text-transform:uppercase">{{item.ticket_number}}</div>
              </v-col>
          </v-row> -->
        <!-- <v-row align="center" class="my-2 px-0 py-4">
            <v-col class="ma-0 pa-0" cols="12">
              <v-row align="center" justify="center" class="ma-0 pa-0">
                <v-col class="d-flex justify-center pa-0 ma-0" cols="12">
                  <div class="route-1">{{ item.valid_days }} {{item}}<span style="text-transform: lowercase;">{{$t("Days")}} </span> , {{ item.max_trip }} {{$t("Trips")}}</div>
                </v-col>
              </v-row>
              <v-row align="center" class="ma-0 pb-0 pt-0">
                <v-col class="ma-0 pa-0 text-center" cols="12">
                  <p class="bth-2 d-flex justify-center">{{ farename }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row> -->
      </v-card>
    </v-container>
    <v-container class="top-frame" v-else>
      <v-card elevation="1" class="card-content">
        <v-col class="pa-4">
          <div align="center" justify="center" class="ma-0 px-4">
            <div class="route-1">{{ route }}</div>
          </div>
          <v-row align="center" class="ma-0 pb-0 pt-0">
            <v-col class="ma-0 pa-0" cols="5">
              <p class="bth d-flex justify-center">{{ orig }}</p>
              <p class="place-desc d-flex justify-center">{{ original }}</p>
            </v-col>
            <v-col class="mb-4 pa-0 d-flex justify-center" cols="2">
              <img
                alt=""
                class="vector"
                :src="require('@/assets/buy-ticket/bus-vector.svg')"
              />
            </v-col>
            <v-col class="ma-0 pa-0" cols="5">
              <p class="bth d-flex justify-center">{{ dest }}</p>
              <p class="place-desc d-flex justify-center">
                {{ destination }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-container>
    <v-container v-if="$partnerACL.isShowButton('qr_code')">
      <v-card elevation="1">
        <v-row class="column-passenger">
          <v-col align="center">
            <p>{{ $t("PleaseScan") }}</p>
          </v-col>
        </v-row>
        <div class="image-scan">
          <img
            alt=""
            class="phone"
            :src="require('@/assets/myticket/mobileqr-img.svg')"
          />
          <img
            alt=""
            class="qrcode"
            :src="require('@/assets/myticket/qr.svg')"
          />
        </div>
        <v-row v-if="disabledBtnHome == false && $partnerACL.isShowButton('qr_code')">
          <v-col align="left">
            <v-btn
              rounded
              class="text-capitalize btn-home"
              v-on:click="clicHome()"
              >Back to {{ $t("Home") }}
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-btn
              v-if="$partnerACL.isShowButton('qr_code')"
              rounded
              class="text-capitalize"
              v-on:click="scanQR()"
              :color="$partnerACL.getButtonColor()"
            >
              {{ $t("ScanQRCode") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="disabledBtnHome == true && $partnerACL.isShowButton('qr_code')">
          <v-col align="center">
            <v-btn
              v-if="$partnerACL.isShowButton('qr_code')"
              rounded
              class="text-capitalize"
              v-on:click="scanQR()"
              :color="$partnerACL.getButtonColor()"
            >
              {{ $t("ScanQRCode") }}
            </v-btn>
          </v-col>
          <v-col align="center">
            <v-btn
              v-if="$partnerACL.isShowButton('qr_code')"
              rounded
              class="text-capitalize"
              v-on:click="showQR()"
              :color="$partnerACL.getButtonColor()"
            >
              {{ $t("ShowQRCode") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CardResultBuyTicket",
  props: {
    item: {
      type: Object,
    },
    orig: {
      type: String,
      default: "BTH",
    },
    original: {
      type: String,
      default: "Bến Thành",
    },
    expticket: {
      type: String,
      default: "Single Trip ticket is valid until 24 hours",
    },
    route: {
      type: String,
      default: "Route 3",
    },
    dest: {
      type: String,
      default: "TLC",
    },
    destination: {
      type: String,
      default: "Thạnh Lộc",
    },
    passengername: {
      type: String,
      default: "Quan Than",
    },
    price: {
      type: String,
      default: "10000",
    },
    currency: {
      type: String,
      default: "VND",
    },
    faretype: {
      type: Number,
      default: 0,
    },
    farename: {
      type: String,
      default: "",
    },
    disabledBtnHome: {
      type: Boolean,
      default: false,
    },
    onHandlerScanQR: {
      /*
        set handler function after click oke/ yes
      */
      type: Function,
      required: false,
    },
    onHandlerShowQR: {
      /*
        set handler function after click oke/ yes
      */
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      validate_end_date: "",
    };
  },
  methods: {
    clicHome() {
      // this.$emit("onClickView", this.params.value)
      this.$router.push({
        name: "Home",
      });
    },
    clickView() {
      // this.$emit("onClickView", this.params.value)
      this.$router.push({
        name: "ViewTicket",
        params: {
          data: this.itemFF,
        },
      });
    },
    scanQR() {
      this.onHandlerScanQR();
    },
    showQR() {
      this.onHandlerShowQR();
    }
  },
  mounted() {
    if (this.item.fare_rule != undefined) {
      this.validate_end_date =
        this.item.validate_close_date == ""
          ? moment(moment().format("YYYY") + "-12-01")
              .endOf("month")
              .format("YYYY-MM-DD hh:mm:ss")
          : moment
              .utc(this.item.validate_close_date)
              .format("YYYY-MM-DD hh:mm:ss");
    }
  },
};
</script>

<style lang="scss" scoped>
.qrcode {
  animation-name: qrmoving;
  animation-duration: 3s;
  position: relative;
  animation-iteration-count: 10;
}
.phone {
  animation-name: phonemoving;
  animation-duration: 3s;
  position: relative;
  animation-iteration-count: 10;
}

@keyframes phonemoving {
  0% {
    right: 20px;
    left: 0px;
    bottom: 0px;
  }
  50% {
    right: 20px;
    left: 30px;
    bottom: 0px;
  }
  100% {
    right: 20px;
    left: 30px;
    bottom: 5px;
  }
}
@keyframes qrmoving {
  0% {
    right: 0px;
    bottom: 20px;
    transform: scale(1);
  }
  25% {
    right: 0px;
    bottom: 50px;
    transform: scale(1);
  }
  50% {
    right: 10px;
    bottom: 50px;
    transform: scale(1);
  }
  75% {
    right: 20px;
    bottom: 50px;
    transform: scale(1);
  }
  100% {
    right: 26px;
    bottom: 50px;
    transform: scale(1.1);
  }
}

.divider {
  border: none;
  width: 2px;
  height: 110px;
}
.v-card.theme--light.v-sheet {
  // position: absolute;
  // border: 0.5px solid #c5c5c5;
  overflow: hidden;
}
.v-application .elevation-1 {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.col-kanan {
  height: 100%;
}

.halfcircle {
  background: #ffffff;
  width: 12.1px;
  height: 22.1px;
  position: absolute;
  top: 45%;
  border: 1px solid black;
  overflow: hidden;
  z-index: 5;
}

.cut-out {
  border-radius: 50px;
  width: 100%;
  height: 20%;
  display: block;
  position: absolute;
  top: 40%;
  background: #fdfdfd;
}

.halfcircle-1 {
  left: -97%;
  box-shadow: inset -1px 2px 2px -1px #888;
}
.halfcircle-2 {
  right: -97%;
  box-shadow: inset 1px 1px 1px 1px #888;
}
.flex-grid {
  display: flex;
}
.col {
  flex: 1;
}
.crop-kiri {
  margin-right: 0%;
  height: 200px;
}
.crop-kanan {
  margin-left: -10%;
  height: 200px;
}
.card-content {
  // width: 90%;
  border: 1px solid rgba(72, 158, 75, 1);
  margin: 0;
  border-radius: 10px;
  max-height: 25%;
}
.content {
  margin-bottom: -12px;
}
.component-1 {
  float: left;
  width: 100%;
  position: relative;
}
.btn-buy-hijau {
  width: 100%;
}
.content-ticket {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
  padding: 1em;
}
.background-ticket {
  display: block;
  width: 100%;
}
.route-1 {
  color: rgba(134, 134, 134, 1);
  font-size: 10px;
}
.flex-wrapper-three {
  margin-top: -10px;
  padding: 0 0 0 0px;
  display: flex;
  align-items: center;
}
.bth {
  font-size: 15px;
  margin: 0;
  padding: 0;
  color: rgba(72, 158, 75, 1);
}
.bth-2 {
  font-size: 13px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: rgba(72, 158, 75, 1);
}
.relative-wrapper-one {
  margin-right: 15px;
  position: relative;
}
.vector {
  width: 80%;
  height: auto;
}
.group {
  position: absolute;
  left: 55px;
  top: -1px;
  transform: rotate(180deg);
}
.flex-wrapper-four {
  margin-left: 10px;
  margin-bottom: -40px;
  // padding: 0 0 0 21px;
  // display: flex;
  // align-items: flex-start;
}
.bn-thanh {
  // @include roboto-10-light;
  margin-top: -15px;
  font-size: 9px;
  color: rgba(75, 177, 78, 1);
  right: 122px;
}
.place-desc {
  font-size: 10px;
  color: rgba(75, 177, 78, 1);
  white-space: nowrap;

  margin-top: -3px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  padding-bottom: 0;
}
.vector-7 {
  width: 100%;
}
.flex-wrapper-five {
  padding: 0 0 0 17px;
  display: flex;
  align-items: flex-start;
}
.flex-wrapper-six {
  margin-right: 43px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.route-information {
  // @include roboto-12-medium;
  font-size: 8px;
  color: rgba(94, 215, 153, 1);
  text-decoration: underline;
  margin-top: 0px;
  margin-left: 25%;
  margin-bottom: -2px;
}
.flat-fare-ticket-is-valid-for-24-hours {
  // @include roboto-8-light;
  font-size: 8px;
  margin-left: 25%;
  color: rgba(75, 177, 78, 1);
  font-style: italic;
}
.num-135000-v-nd {
  // @include roboto-14-medium;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
  color: rgba(242, 153, 0, 1);
}
.group-18785 {
  border-radius: 23px;
  height: 31px;
  width: 80px;
  padding: 5px 6px 2px 4px;
  position: relative;
  border: 1px solid rgba(75, 177, 78, 0.35);
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .headticket {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .flex-wrapper {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .back-img {
    margin-right: 12px;
  }
  .head-ticket {
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: black;
    margin: 0;
  }
  .viewticket {
    padding: 0px 15px 10px 10px;
  }
  .card-top-frame {
    background-color: white;
    margin-bottom: 17px;
    border-radius: 12px;
    padding: 9px 51px 18px 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .farename {
    font-family: "Roboto";
    color: rgba(72, 158, 75, 1);
    text-align: center;
  }
  .farerule {
    font-family: "Roboto";
    color: rgba(134, 134, 134, 1);
    text-align: center;
    margin-bottom: 26px;
  }
  .fareexp {
    color: rgba(75, 177, 78, 1);
    font-style: italic;
    text-align: center;
  }
  .flex-wrapper-one {
    padding: 22px 20px 27px 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-image: url("~@/assets/myticket/card-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .psg {
    margin-left: 0px;
    font-family: "Roboto";
    color: rgba(173, 179, 188, 1);
  }
  .frame-11 {
    margin-bottom: 41px;
    // display: flex;
    justify-content: center;
  }
  .quan-twan {
    font-family: "Roboto";
    color: rgba(60, 60, 60, 1);
    font-weight: bold;
    font-size: 16px;
  }
  .vare-value {
    font-size: 16px;
    width: 128px;
    font-family: "Roboto";
    color: rgba(242, 153, 0, 1);
    text-align: right;
  }

  .btn-home {
    background: #ffffff;
    border: 1px solid rgba(75, 177, 78, 0.2);
    color: #4bb14e;
    box-sizing: border-box;
    border-radius: 22.5px;
  }

  .vector-2 {
    margin-top: 5px;
    margin-left: 400%;
  }

  .route-1 {
    color: rgba(134, 134, 134, 1);
    margin-bottom: 1px;
  }

  .bth {
    font-size: 24px;
    color: rgba(72, 158, 75, 1);
  }
  .bth-2 {
    font-size: 15px;
    font-weight: 800;
    margin: 0;
    padding: 0;
    color: rgba(72, 158, 75, 1);
  }
  .bts {
    margin-left: 100%;
    margin-top: -75px;
    font-size: 24px;
    color: rgba(72, 158, 75, 1);
  }
  .flex-wrapper-location {
    display: flex;
  }

  .bn-thanh {
    margin-top: -10px;
    margin-left: -5px;
    font-size: 10px;
    text-align: left;
    color: rgba(75, 177, 78, 1);
    &:not(:last-of-type) {
      margin-right: 180px;
    }
  }
  .bn-bbc {
    margin-top: -10px;
    font-size: 10px;
    color: rgba(75, 177, 78, 1);
    margin-right: -50px;
  }

  .flex-wrapper-head {
    width: 365.28px;
    margin-bottom: 11px;
    border-radius: 12px;
    padding: 13px 20px 10px 34px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 05), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
  }
  .multidays {
    align-content: center;
    margin-left: 50px;
    font-size: 10px;
    color: rgba(75, 177, 78, 1);
  }

  .button-single {
    margin-left: 45%;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) and (orientation: portrait) {
  .headticket {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .back-img {
    margin-right: 12px;
  }
  .head-ticket {
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: black;
    margin: 0;
  }
  .viewticket {
    padding: 30px 15px 10px 10px;
  }
  .card-top-frame {
    background-color: white;
    margin-bottom: 17px;
    border-radius: 12px;
    padding: 9px 51px 18px 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .farename {
    font-family: "Roboto";
    color: rgba(72, 158, 75, 1);
    text-align: center;
  }
  .farerule {
    font-family: "Roboto";
    color: rgba(134, 134, 134, 1);
    text-align: center;
    margin-bottom: 26px;
  }
  .fareexp {
    color: rgba(75, 177, 78, 1);
    font-style: italic;
    text-align: center;
  }
  .flex-wrapper-one {
    padding: 13px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-image: url("~@/assets/myticket/card-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .psg {
    margin-left: 0px;
    font-family: "Roboto";
    color: rgba(173, 179, 188, 1);
  }
  .frame-11 {
    margin-bottom: 41px;
    display: flex;
  }
  .quan-twan {
    font-family: "Roboto";
    color: rgba(60, 60, 60, 1);
    font-weight: bold;
    font-size: 16px;
  }
  .vare-value {
    font-size: 16px;
    width: 128px;
    font-family: "Roboto";
    color: rgba(242, 153, 0, 1);
    text-align: right;
  }

  .btn-home {
    background: #ffffff;
    border: 1px solid rgba(75, 177, 78, 0.2);
    color: #4bb14e;
    box-sizing: border-box;
    border-radius: 22.5px;
  }

  .vector-2 {
    margin-top: 5px;
    margin-left: 70%;
  }

  .route-1 {
    color: rgba(134, 134, 134, 1);
    margin-bottom: 1px;
  }

  .bth {
    font-size: 24px;
    color: rgba(72, 158, 75, 1);
  }
  .bth-2 {
    font-size: 13px;
    font-weight: 800;
    margin: 0;
    padding: 0;
    color: rgba(72, 158, 75, 1);
  }
  .bts {
    margin-left: 100%;
    margin-top: -75px;
    font-size: 24px;
    color: rgba(72, 158, 75, 1);
  }
  .flex-wrapper-location {
    display: flex;
  }

  .bn-thanh {
    margin-top: -10px;
    margin-left: -5px;
    font-size: 10px;
    text-align: left;
    color: rgba(75, 177, 78, 1);
    &:not(:last-of-type) {
      margin-right: 180px;
    }
  }
  .bn-bbc {
    margin-top: -10px;
    font-size: 10px;
    color: rgba(75, 177, 78, 1);
    margin-right: -50px;
  }

  .flex-wrapper-head {
    width: auto;
    margin-bottom: 11px;
    border-radius: 12px;
    padding: 13px 20px 10px 34px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 05), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
  }
  .multidays {
    align-content: center;
    margin-left: 50px;
    font-size: 10px;
    color: rgba(75, 177, 78, 1);
  }
  .button-single {
    margin-left: 30%;
  }

  .ticket {
    font-weight: bold;
    font-size: 34px;
    line-height: 118.88%;
  }
  .text-color-green {
    color: #489e4b;
  }
}
</style>