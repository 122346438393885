<template>
  <div align="center" class="head">
    <!-- <v-img
      class="img"
      :src="require('../../assets/myticket/bus-myticket.png')"
    >
    </v-img> -->
    <div align="left" >
      <v-container>
        <p class="p">{{$t('YourTrip')}}</p>
        <br>
        <p class="p">{{$t('Abouttobegin')}}</p>

      <CardResultBuyTicket
      :onHandlerScanQR="clickScanQR"
        :disabledBtnHome="false"
      />
      <!-- <CardResultBuyTicketMultidays
      :onHandlerScanQR="clickScanQR"
        :disabledBtnHome="true"/> -->

      </v-container>
  
    </div>
  </div>
</template>

<script>
import CardResultBuyTicket from '@/components/ResultBuyTicket.vue'
// import CardResultBuyTicketMultidays from '@/components/ResultBuyTicketMultidays.vue'

export default {
  name: "ResultTicket",
  components: {
    CardResultBuyTicket,
    // CardResultBuyTicketMultidays,
    },
  data() {
    return {
      swipeOptions: {
        startSlide: 0,
        speed: 300,
        auto: 4000,
        continuous: true,
        disableScroll: false,
        stopPropagation: false
      },
      item:this.$route.params.data,
      ticktoken:this.$route.params.data.ticket_token ,
      farename:this.$route.params.data.fare_name ,
      farerule:this.$route.params.data.max_trip ,
      fareexp:this.$route.params.data.ticket_expired_date ,
      passengername:this.$route.params.data.psg_name ,
      farevalue:this.$route.params.data.fare_value,
    }
  },
  props: {
    // farename: {
    //   type: String,
    //   default: "All Route - 3 Days Ticket"
    // },
    // farerule: {
    //   type: String,
    //   default: "Total 6 trip for 3 days - 2 trip/day"
    // },
    // fareexp: {
    //   type: String,
    //   default:
    //     "Multi-days ticket is valid until 22 January 2021"
    // },
    passenger: { type: String, default: "Passenger" },
    // passengername: { type: String, default: "Quan Twan" },
    // farevalue: { type: String, default: "600,000 VNĐ" }
  },
  mounted(){
    // console.log(item)
  },
  methods:{
     prev() {
      this.$refs.mySwipe.prev()
    },
    next() {
      this.$refs.mySwipe.next()
    },
    clickScanQR() {
      // this.$emit("onClickView", this.params.value)
      this.$router.push({
        name: 'ScanQR',
        params: {
          data:this.ticktoken
        }
      })
    },

    // clickView() {
      
    //   this.onClickView
    //   console.log("okok")
    // },
    // clickNo(){
    //   this.dialog = false
    // },
  },
};
</script>

<style lang="scss" scoped>
// .head{
//   margin-left: -10px;
//   opacity: 10;
//   overflow: hidden;
//   height: 100%;
//   background: #000000;  /* fallback colour. Make sure this is just one solid colour. */
//   background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/myticket/bus-myticket.png') ; /* The least supported option. */
// }
.img{
   object-fit: cover;
   opacity: 0.4;
   bottom: 0;
}

.content{
  // z-index: 1px;
  top: -100%;
  // opacity: 10;
  background-color: #000000;
}
.p{
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 118.88%;
  color: #FFFFFF;
  padding: 0px 20px 0px 20px;
}
</style>